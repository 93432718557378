import { SET_GALERIES_COUNT, SET_ARTWORKS_COUNT, SET_ARTISTS_COUNT, SET_COLLECTORS_COUNT, SET_CERTIFIED_ARTWORKS_COUNT } from "../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  galeryCount: -1,
  artistCount: -1,
  collectorCount: -1,
  registeredCount: -1,
  certifiedCount: -1,
  loading: false,
  message: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_GALERIES_COUNT:{
      return {
        ...state,
        galeryCount: action.payload,
        loaded: action.loaded ? action.loaded : false
      };
    }
    case SET_ARTISTS_COUNT:{
        return {
          ...state,
          artistCount: action.payload,
          loaded: action.loaded ? action.loaded : false
        };
      }
    case SET_CERTIFIED_ARTWORKS_COUNT:{
        return {
          ...state,
          certifiedCount: action.payload,
          loaded: action.loaded ? action.loaded : false
        };
    }
    case SET_ARTWORKS_COUNT:{
        return {
          ...state,
          registeredCount: action.payload,
          loaded: action.loaded ? action.loaded : false
        };
    }
    case SET_COLLECTORS_COUNT:{
        return {
          ...state,
          collectorCount: action.payload,
          loaded: action.loaded ? action.loaded : false
        };
    }
    default:
      return state;
  }
  
};
