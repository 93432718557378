import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";
import axios from "util/Api";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ name, email, password }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("admin/login", {
        email: email,
        password: password
      })
      .then(res => {
        const { data } = res;

        if (data.type === "success") {
          localStorage.setItem("token", JSON.stringify(data.data.api_token));
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.data.api_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.data.api_token });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getUser = () => {
  return dispatch => {
    axios
      .get("actor/me")
      .then(({ data }) => {
        if (data && data.type === "success") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.data });
        } else {
          dispatch({ type: FETCH_SUCCESS });
          //dispatch({ type: SIGNOUT_USER_SUCCESS });
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_START });
        localStorage.removeItem("token");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    localStorage.removeItem("token");
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    // axios
    //   .post("auth/logout")
    //   .then(({ data }) => {
    //     if (data.result) {
    //       dispatch({ type: FETCH_SUCCESS });
    //       localStorage.removeItem("token");
    //       dispatch({ type: FETCH_SUCCESS });
    //       dispatch({ type: SIGNOUT_USER_SUCCESS });
    //     } else {
    //       dispatch({ type: FETCH_ERROR, payload: data.error });
    //     }
    //   })
    //   .catch(function(error) {
    //     dispatch({ type: FETCH_ERROR, payload: error.message });
    //     console.log("Error****:", error.message);
    //   });
  };
};
