import {
    SET_LIST_EDITIONS
} from "../constants/ActionTypes";

const INIT_STATE = {
    error:"",
    editions: [],
    loading: false,
    message: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LIST_EDITIONS:{
            return {
                ...state,
                editions: action.payload,
                loaded: action.loaded ? action.loaded : false
            };
        }
        default:
            return state;
    }

};
