import { SET_LIST_USERS, SET_LIST_EVENTS } from "../constants/ActionTypes";

const INIT_STATE = {
  user: [],
  events: [],
  error: "",
  loading: false,
  message: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LIST_USERS: {
      return {
        ...state,
        users: action.payload,
        loaded: action.loaded ? action.loaded : false
      };
    }
    case SET_LIST_EVENTS: {
      return {
        ...state,
        events: action.payload,
        loaded: action.loaded ? action.loaded : false
      };
    }
    default:
      return state;
  }
};
