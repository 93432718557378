import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Data from "./Data";
import Statistics from "./Statistics";
import Editions from "./Editions";

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    data: Data,
    statistics: Statistics,
    editions: Editions
  });
